import Image from "next/legacy/image";

export default function Footer(footerData) {
    const footerOptions = footerData?.footer?.data?.siteOptions?.siteOptions;
    let menuItems = footerData?.footer?.data?.menu?.menuItems?.edges

    const groupMenuChildren = (
        data = []
    ) => {
        var output = [];

        if(data?.length){
            let chunks = [];
            const chunkSize = Math.ceil(data.length/2);

            for (let i = 0; i < data.length; i +=  chunkSize){
                const chunk = data.slice(i, i + chunkSize);
                chunks.push(chunk);
            }

            output = chunks;
        }

        return output;
    }

    menuItems = (groupMenuChildren(menuItems));

    return (
    <footer>
        <div id="top-footer-container" className="container-fluid">
            <div className="container">
                <div>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: footerOptions?.contactInformation}}>
                        </div>
                        <div id="top-footer-social-container">
                            {
                                footerOptions?.facebookLink && (
                                    <a aria-label="Facebook Link (opens in a new tab)" href={`${footerOptions?.facebookLink}`} target="_blank">
                                        <span className="icon-facebook"></span>
                                    </a>
                                )
                            }
                            {
                                footerOptions?.instagramLink && (
                                    <a aria-label="Instagram Link (opens in a new tab)" href={`${footerOptions?.instagramLink}`} target="_blank">
                                        <span className="icon-instagram"></span>
                                    </a>
                                )
                            }
                            {
                                footerOptions?.linkedinLink && (
                                    <a aria-label="LinkedIn Link (opens in a new tab)" href={`${footerOptions?.linkedinLink}`} target="_blank">
                                        <span className="icon-linkedin"></span>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            {
                                menuItems && menuItems.length >0 && menuItems.map((item, index) => (
                                   <div key={`menu-wrapper-${index}`}>
                                        <ul>
                                            {
                                                item && item.map((item) => (
                                                    <li key={`menu-item-${item.node.id}`}>{
                                                        <a href={`${item.node.path}`}>
                                                            <span className="icon-arrow"></span>
                                                            {item.node.label}
                                                        </a>
                                                    }</li>
                                                ))
                                            }
                                        </ul>
                                   </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>      
        </div>  
        <div id="bottom-footer-container" className="container-fluid">
            <div className="container">
                <div>
                    <div>
                        <div>
                            <div dangerouslySetInnerHTML={{__html: footerOptions?.copyrightContent}}></div>
                            <a aria-label="Handcrafted by (opens link in a new tab)" href="https://cacpro.com" target="_blank">
                                Handcrafted by<span className="icon-cacpro"></span>
                            </a>
                        </div>
                        <p className="disclaimer" dangerouslySetInnerHTML={{__html: footerOptions?.disclaimerText}}></p>
                    </div>
                    <div>
                        <div>
                            <div>
                                <a aria-label="(opens link in a new tab)" href="https://www.guidestar.org/profile/shared/2abc1114-5801-47b7-9268-d81251183373" target="_blank">
                                    <img loading="lazy" width={80} height={80} alt="Guidestar Logo" src="https://widgets.guidestar.org/TransparencySeal/7663207" />
                                </a>
                            </div>
                            {
                                footerOptions?.logos && footerOptions?.logos.length>0 && footerOptions?.logos.map((item, index) => (
                                    <div key={`logo-${index}`}>{
                                        item.link ? (
                                            <a aria-label="Logo Link (opens in a new tab)" href={`${item.link}`} target="_blank">
                                                <Image
                                                    src={item?.logo?.sourceUrl}
                                                    srcSet={item.logo?.srcSet}
                                                    sizes={item?.logo?.sizes}
                                                    height={
                                                        item?.logo?.mediaDetails?.height
                                                    }
                                                    quality={100}
                                                    width={item?.logo?.mediaDetails?.width}
                                                    alt={item.logo.altText}
                                                    title={item.logo.title}
                                                />
                                            </a>
                                        ) : (
                                            <Image
                                                src={item?.logo?.sourceUrl}
                                                srcSet={item.logo?.srcSet}
                                                sizes={item?.logo?.sizes}
                                                height={
                                                    item?.logo?.mediaDetails?.height
                                                }
                                                quality={100}
                                                width={item?.logo?.mediaDetails?.width}
                                                alt={item.logo.altText}
                                                title={item.logo.title}
                                            />
                                        )
                                    }</div>
                                ))
                            }
                        </div>
                        <p className="mobile-disclaimer" dangerouslySetInnerHTML={{__html: footerOptions?.disclaimerText}}></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}